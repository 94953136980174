import axios from '@/components/plugins/axios'
const url = process.env.VUE_APP_API_URL

class Cities {
  get (slug, params, filters = null) {
    let options = '?page=' + params.page + '&per_page=' + params.per_page
    if (typeof (params.search) !== 'undefined') {
      options += '&search=' + params.search
    }
    if (filters !== null) {
      Object.keys(filters).forEach(key => {
        options += '&filters[' + key + ']=' + filters[key]
      })
    }
    return axios.get(url + '/public/cities/' + slug + options)
  }

  getAll (params, filters = null) {
    let options = '?page=' + params.page + '&per_page=' + params.per_page
    if (typeof (params.search) !== 'undefined') {
      options += '&search=' + params.search
    }
    if (filters !== null) {
      Object.keys(filters).forEach(key => {
        options += '&filters[' + key + ']=' + filters[key]
      })
    }
    return axios.get(url + '/public/cities' + options)
  }
}
export default new Cities()

<template>
  <main style="padding-top: 100px">
    <div class="page_header border-bottom element_to_stick">
      <b-container>
        <b-row>
          <b-col md="12">
            <h1>Inregistrare ca livrator</h1>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container class="margin_30_20">
      <b-row>
        <div class="main-wrapper">
          <div class="page messages">
            <div data-placeholder="messages"></div>
            <div data-bind="scope: 'messages'">
              <!-- ko if: cookieMessages && cookieMessages.length > 0 --><!-- /ko --><!-- ko if: messages().messages && messages().messages.length > 0 --><!-- /ko -->
            </div>
          </div>
          <div class="columns">
            <div class="column main">
              <input name="form_key" type="hidden" value="igZl5wCcEPUSOKTy" />
              <div
                id="authenticationPopup"
                data-bind="scope:'authenticationPopup'"
                style="display: none"
              >
              </div>
              <b-row>
                <b-col offset-md="3" md="6">
                  <div class="form-group">
                    <label>Adresa de E-Mail <small>*Obligatoriu</small></label>
                    <b-form-input placeholder="E-Mail" type="text" v-model="registerData.email"></b-form-input>
                  </div>
                  <div class="form-group">
                    <label>Nume <small>*Obligatoriu</small></label>
                    <b-form-input placeholder="Nume" type="text" v-model="registerData.first_name"></b-form-input>
                  </div>
                  <div class="form-group">
                    <label>Prenume <small>*Obligatoriu</small></label>
                    <b-form-input placeholder="Prenume" type="text" v-model="registerData.last_name"></b-form-input>
                  </div>
                  <div class="form-group">
                    <label>Telefon <small>*Obligatoriu</small></label>
                    <b-form-input placeholder="Telefon" type="text" v-model="registerData.phone"></b-form-input>
                  </div>
                  <div class="form-group">
                    <label>Orasul unde vrei sa livrezi <small>*Obligatoriu</small></label>
                    <b-form-select v-model="registerData.city" :options="citiesOptions" size="sm" class="form-control"></b-form-select>
                  </div>
                  <div class="form-group">
                    <label>Mijloc de transport <small>*Obligatoriu</small></label>
                    <b-form-select v-model="registerData.vehicle_type" :options="autoTypesOptions" size="sm" class="form-control"></b-form-select>
                  </div>
                  <div class="form-group">
                    <label>Parola <small>*Obligatoriu</small></label>
                    <b-form-input type="password" v-model="registerData.password"></b-form-input>
                  </div>
                  <div class="form-group">
                    <label>Confirmare parola <small>*Obligatoriu</small></label>
                    <b-form-input type="password" v-model="registerData.c_password"></b-form-input>
                  </div>
                  <b-button @click="registerDriver" variant="primary">INREGISTRARE</b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-row>
      <b-row>
        <b-col md="12">
          <p class="mt-5 text-center">
            <b
              ><span style="text-decoration: underline"
                >ACORD PRELUCRARE ŞI STOCARE DATE CU CARACTER PERSONAL</span
              ></b
            >
          </p>
          <p>
            Prin inregistrarea ca livrator pe siteul www.coressy.com, esti de
            acord sa ne furnizezi informaţii personale, astfel iţi exprimi
            acordul fără echivoc, că eşti informat cu privire la stocarea şi
            prelucrarea datelor personale citind paginile
            <a href="/protectia-datelor">Politica de confidențialitate </a> si
            <a href="/termeni-si-conditii">Termeni si conditii</a> . Acordul
            dumneavoastră este liber consimţit şi fără vreo condiţionare din
            partea noastră.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
import PublicCities from '@/components/publicEndpoints/cities'
import Auth from '@/components/auth'
import { bus } from "../../main";

export default {
  name: "InregistrareLivrator",
  data() {
    return {
      isBusy: false,
      registerData: {
        email: '',
        first_name: '',
        last_name: '',
        phone: '',
        city: '',
        vehicle_type: 'masina'
      },
      cities: []
    };
  },
  methods: {
    getCities() {
      const params = {
        page: 1,
        per_page: 100
      }
      PublicCities.getAll(params).then(response => {
        this.cities = response.data.data
      })
    },
    async registerDriver () {
      let registerResponse = await Auth.registerDriver(this.registerData)
      if (registerResponse.success) {
        localStorage.setItem('token', registerResponse.data.token)
        localStorage.setItem('userInfo', JSON.stringify(registerResponse.data.user_data))
        bus.$emit('loggedIn', registerResponse.data.user_data)
        this.$router.push({ name: "driver.dashboard" });
      } else {
        const errorMessage = registerResponse.data.message;
        if (errorMessage === 'Validation Error.') {
          const errorFields = registerResponse.data.data;
          if (typeof errorFields['email'] !== "undefined" && errorFields['email'][0] === 'The email has already been taken.') {
            this.$swal.fire({
              title: 'Email deja inregistrat',
              icon: 'warning',
              text: 'Adresa de email este deja inregistrata. Va rugam sa folositi alta adresa de email daca aveti deja un cont de client'
          });
        } else {
            this.$toastr.e('Va rugsam sa completati toate campurile obligatorii');
          }
        }
      }
    },
  },
  mounted() {
    this.getCities()
  },
  computed: {
    citiesOptions() {
      const returnData = []
      this.cities.forEach(city => {
        const tmpObj = {
          value: city.id,
          text: city.name
        }
        returnData.push(tmpObj)
      })
      return returnData
    },
    autoTypesOptions() {
      return [
        {
          value: 'masina',
          text: 'Masina'
        },
        {
          value: 'scuter',
          text: 'Scuter'
        },
        {
          value: 'bicicleta',
          text: 'Bicicleta'
        }
      ]
    }
  },
};
</script>
